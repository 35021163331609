/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import { message, Image, Button } from "antd";
import { motion } from "framer-motion";
import isVietnamesePhoneNumber from "../utils/isCorrectNumberPhone";
import api from "../api";
import CSKHImage from "../assets/new-ui/cskh.png";

import "./cskh.scss";


export default function CSKH() {
  const [dataCSKH, setDataCSKH] = useState({});
  console.log("dataCSKH", dataCSKH);
  const [token] = useState(localStorage.getItem("access_token"));
  useEffect(async () => {

    // if (token) {
      try {
        const { data } = await api.get("/cskh/all");
        setDataCSKH(data.data[0]);
      } catch (error) {
        message.error("Xảy ra lỗi, vui lòng thử lại sau");
      }
    // }
  }, []);
  return (
    <>
      <div className="header-screen mb-0">CSKH</div>
      <div
        style={{
          backgroundColor: "#fff",
        }}
      >
        <motion.div
          className="cskh"
          style={{
            background: "#fff",
            minHeight: 200,
            minWidth: 300,
          }}
        >
          <div className="banner-header-body mb-20">
            <Image className="main-banner" src={CSKHImage} preview={false} />
          </div>
          <div className="main-body text-center">
            <h2 className="main-title">CHĂM SÓC KHÁCH HÀNG</h2>
            <p className="sub-title">Thời gian làm việc: 08:00 đến 19:30</p>
            <div className="button-group">
              <a className="btn-genaral btn-submit mb-20" href={`tel:${dataCSKH.hotline}`}>
                LIÊN HỆ HOTLINE
              </a>
              <div style={{display: "flex", justifyContent: "center"}}>
                <div
                  className="btn-genaral btn-telegram"
                  onClick={() => {
                    window.open(dataCSKH.telegram);
                  }}
                >
                  <img src="https://cdn3.iconfinder.com/data/icons/social-media-chamfered-corner/154/telegram-512.png"></img>
                </div>
                <div
                  className="btn-genaral btn-zalo"
                  onClick={() => {
                    window.open(`https://zalo.me/${dataCSKH.zalo}`);
                  }}
                >
                  <img src="https://cdn.haitrieu.com/wp-content/uploads/2022/01/Logo-Zalo-Arc.png"></img>
                </div>
              </div>
              {/* <div
                className="btn-submit"
                type="primary"
                shape="round"
                onClick={toCSKH}
              >
                Bấm để liên hệ
              </div> */}
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
}
