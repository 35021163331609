/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider, useSelector, useDispatch } from "react-redux";
import _store from "./redux/store";
import * as actions from "./redux/actions/auth";
import api from "./api";
import Home from "./screens";
import UpdateBanking from "./screens/update-banking";
import Login from "./screens/login";
import Signup from "./screens/signup";
import Profile from "./screens/profile";
import Wallet from "./screens/wallet";
import Loan from "./screens/loan"
import CSKH from "./screens/cskh";
import { motion } from "framer-motion";
import Personal from "./screens/personal";
import UserInfo from "./screens/user-info";
import MyInfo from "./screens/my-info";
import ContactRelative from './screens/contact-relatives'
import Navigator from "./components/Navigator";
import { message } from "antd";
const history = createBrowserHistory();

function App() {
  // const { width } = useScreen();
  return (
    <>
      <Provider store={_store}>
        <Router history={history}>
          <Routers />
        </Router>
      </Provider>
    </>
  );
}

const Routers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const localtion = useLocation();
  const { status } = useSelector((state) => state._auth);
  const [token] = useState(localStorage.getItem("access_token"));
  const location = useLocation();
  React.useEffect(() => {
    checkLock(true);
  }, []);

  // React.useEffect(() => {
  //   checkLock(false);
  // }, [location]);

  const checkLock = async (isFirst) => {
    try {
      if (
        location.pathname == "/login" ||
        location.pathname == "/signup" 
      )
        return;
      const { data } = await api.get("/users/profile");
      if (isFirst) dispatch(actions.initialLogin(data.data));
    } catch (error) {}
  };
  useEffect(() => {
    // if (token) {
    //   (async () => {
    //     try {
    //       const { data } = await api.get("/users/profile");
    //       dispatch(actions.initialLogin(data.data));
    //     } catch (err) {}
    //   })();
    // } else {
    //   history.push("/home");
    // }
    if (!token) history.push("/home");
  }, [status]);

  return (
    <Switch>
      <Route path="/">
        <motion.div>
          <div
            style={{
              height: `${
                ["/home", "/profile", "/wallet", "/loan", "/cskh"].includes(
                  localtion.pathname
                )
                  ? "calc(100vh - 55px)"
                  : "100vh"
              }`,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Switch>
              <Route path="/" component={Home} exact />
              <Route path="/home" component={Home} exact />
              <Route path="/personal-information" component={Personal} exact />
              <Route path="/bank-information" component={UpdateBanking} exact />
              <Route path="/profile" component={Profile} exact />
              <Route path="/contact-realtive" component={ContactRelative} exact />
              <Route path="/user-info" component={UserInfo} />
              <Route path="/my-info" component={MyInfo} />
              <Route path="/wallet" component={Wallet} exact />
              <Route path="/loan" component={Loan} exact />
              <Route path="/cskh" component={CSKH} exact />
              <Route path="/login" component={Login} exact />
              <Route path="/signup" component={Signup} exact />
            </Switch>
          </div>
          {["/", "/home", "/profile", "/wallet", "/loan", "/cskh"].includes(
            localtion.pathname
          ) && (
            <div
              style={{
                position: "fixed",
                zIndex: 1000,
                bottom: 0,
                minWidth: "100%",
              }}
            >
              <Navigator />
            </div>
          )}
        </motion.div>
      </Route>
    </Switch>
  );
};
export default App;
