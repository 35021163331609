/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  Carousel,
  Image,
  Input,
  message,
  Rate,
  Select,
  Typography,
  Divider,
  Modal,
} from "antd";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ContractDialog from "../components/ContractDialog";
import api from "../api";

import "./loan.scss";

const timeLoans = [6, 12, 18, 24, 36, 48];

export default function Loan() {
  const [token] = useState(localStorage.getItem("access_token"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const [value, setValue] = useState(0);
  const [times, setTimes] = useState(6);
  // const { status, profile } = useSelector((state) => state._auth);
  const { status } = useSelector((state) => state._auth);
  let [openDialog, setOpenDialog] = useState(false);
  const router = useHistory();
  const [profile, setProfile] = useState({});

  useEffect(() => {
    loadDataProfile();
    getAmountFromSectionStorage();
  }, []);

  const loadDataProfile = async () => {
    try {
      const { data } = await api.get("/users/profile");
      setProfile(data.data);
    } catch (err) {}
  };

  const getAmountFromSectionStorage = () => {
    const amount = sessionStorage.getItem("amount");
    const times = sessionStorage.getItem("times");
    if (amount) {
      setValue(Number(amount));
    }
    if (times) {
      setTimes(Number(times));
    }
  };

  const saveDataToSectionStorage = () => {
    sessionStorage.setItem("amount", value);
    sessionStorage.setItem("times", times);
  };

  async function setupContract() {
    saveDataToSectionStorage();
    if (!status) {
      router.push("/login");
      return;
    }
    if (checkNotKyc()) {
      message.error("Vui lòng nhập đầy đủ thông tin");
      router.push("/my-info");
      return;
    }
    if (value < 20000000) {
      message.error("Hãy nâng hạn mức vay");
      return;
    }
    if (value > 900000000) {
      message.error("Hạn mức vay tối đa 900tr");
      return;
    }
    let contract = {};
    contract["times"] = times;
    contract["amount"] = Number(value);
    localStorage.setItem("contract", JSON.stringify(contract));
    setOpenDialog(3);
    // await api.post("/contracts", {
    //   times: times || 6,
    //   amount: Number(value) || 20000000,
    // });
    // router.push("/wallet");
  }
  // useEffect(async () => {
  //   if (token) {
  //     try {
  //       const { data } = await api.get("/users/profile");
  //       if (data.data.isLocked) showModal();
  //     } catch (err) {}
  //   } else {
  //     router.push("/login");
  //   }
  // }, []);

  const handleClick = async (obj) => {
    console.log("argument from Child: ", obj);
    const { data } = await api.get("/users/profile");
    if (data.data.isLocked) {
      showModal();
      return;
    }
    if (obj.isOpen) setupContract();
  };

  const setFalseDialogProp = () => {
    setOpenDialog(4);
    // openDialog = false
  };

  const checkNotKyc = () => {
    console.log("profile", profile);
    return (
      profile &&
      (!profile.kyc ||
        !profile.kyc.bank ||
        !profile.kyc.job ||
        !profile.kyc.income ||
        !profile.kyc.purpose ||
        !profile.kyc.relative_name1 ||
        !profile.kyc.relative_number1 ||
        !profile.kyc.relative1 ||
        !profile.kyc.relative_name2 ||
        !profile.kyc.relative_number2 ||
        !profile.kyc.relative2 ||
        !profile.kyc.name ||
        !profile.kyc.address ||
        !profile.kyc.dob ||
        !profile.kyc.id_number ||
        !profile.kyc.id_back ||
        !profile.kyc.id_front ||
        !profile.kyc.id_with_face ||
        !profile.kyc.marriage ||
        !profile.kyc.education)
    );
  };
  return (
    <>
      <div className="header-screen">Vay tiền</div>
      <div
        style={{
          backgroundColor: "#fff",
        }}
      >
        <div>
          <motion.div
            style={{
              background: "#fff",
              padding: "10px 10px",
              minHeight: 200,
              minWidth: 300,
            }}
          >
            <Typography.Title
              level={5}
              style={{ textAlign: "center", fontSize: 21 }}
            >
              Số tiền muốn vay (VNĐ)
            </Typography.Title>
            <Typography.Title
              level={1}
              style={{ textAlign: "center", fontSize: 17 }}
            >
              Hạn mức vay từ 20 triệu đến 900 triệu VNĐ
            </Typography.Title>
            <Input
              value={value}
              onChange={(e) => setValue(Number(e.target.value))}
              type="number"
            />
            <Typography.Title
              level={4}
              style={{ textAlign: "center", marginTop: "10px" }}
            >
              {value.toLocaleString()} VNĐ
            </Typography.Title>
            <div>
              <div className="times">
                {timeLoans.map((time, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        time === times ? "times--item active" : "times--item"
                      }
                    >
                      <div onClick={() => setTimes(time)}>{time} tháng</div>
                    </div>
                  );
                })}
                <Divider className="divider-custom" />
              </div>
              <div className="money-per-month mb-10">
                <p className="mb-5 title">Hàng tháng phải trả</p>
                <p className="mb-0">
                  <span className="mr-5 pdl-5">
                    {Math.ceil(value / times + value * 0.009).toLocaleString()}{" "}
                    VNĐ
                  </span>
                  {/* <span className="sub-title"> (Bao gồm lãi suất 0.9%: )</span> */}
                </p>
                <Divider className="divider-custom" />
              </div>
            </div>

            {/* <motion.div
              className="btn"
              whileTap={{
                opacity: 0.7,
                scale: 0.9,
              }}
              onClick={setupContract}
            >
              <Typography.Text
                className="btn-text"
                style={{ fontSize: 20, fontWeight: 700, color: "#fff" }}
              >
                Vay ngay
              </Typography.Text>
            </motion.div> */}
            <ContractDialog
              profile={profile}
              handleClick={handleClick}
              clickLoanNow={setupContract}
              openDialog={openDialog}
              setFalseDialogProp={setFalseDialogProp}
              contract={{
                amount: value,
                times,
              }}
            />
            <Modal className="noti-modal" visible={isModalOpen}>
              <p>Hồ sơ quý khách đang tạm khoá, vui lòng liên hệ CSKH</p>
              <div className="to-cskh" onClick={() => router.push("/cskh")}>
                CSKH
              </div>
            </Modal>
          </motion.div>
        </div>
      </div>
    </>
  );
}
