/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Typography, Checkbox, message } from "antd";
import moment from "moment";
import api from "../api";
import isVietnamesePhoneNumber from "../utils/isCorrectNumberPhone";
import _signned from "../assets/signned.jpg";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import converter from "../utils/converterBase64ToBinary";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { BASE_URL } from "../utils/constant";
export default function App({
  profile,
  handleClick,
  clickLoanNow,
  openDialog,
  setFalseDialogProp,
  contract,
}) {
  const padRef = useRef();
  const [visible, setVisible] = useState(false);
  const [dataCSKH, setDataCSKH] = useState({});
  // const [contract, setContract] = useState({ amount: 0, times: 6 });
  const [ticked, setTicked] = useState(false);
  const router = useHistory();
  // const [profile, setProfile] = useState({});
  const [token] = useState(localStorage.getItem("access_token"));

  useEffect(async () => {
    if (token) {
      const dataCSKH = await api.get("/cskh/all");
      setDataCSKH(dataCSKH.data.data[0]);
    }
  }, []);

  useEffect(() => {
    if (!openDialog) return;
    if (openDialog && openDialog === 3) setVisible(true);
  }, [openDialog]);

  async function finishContract() {
    // if (!ticked) {
    //   message.error(
    //     "Vui lòng tick xác nhận thông tin trước khi đăng ký khoản vay"
    //   );
    //   return;
    // }
    if (padRef.current.isEmpty()) {
      message.error("Vui lòng ký tên để hoàn thành đăng ký khoản vay!");
      return;
    }
    const contract = JSON.parse(localStorage.getItem("contract"));
    if (!contract) {
      message.error("Có lỗi sảy ra, vui lòng thử lại");
      setVisible(false);
      return;
    }
    let formData = new FormData();
    const dataURI = converter(padRef.current.toDataURL());
    formData.append("file", dataURI);

    const { data: signUrl } = await axios.post(BASE_URL + "/upload", formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    let objData = {
      ...profile.kyc,
      chu_ky: signUrl.secure_url,
    };
    delete objData.status;
    await api.post("/users/verify", objData);
    await api.post("/contracts", {
      times: contract.times || 6,
      amount: contract.amount || 20000000,
    });
    message.success("Đăng ký khoản vay thành công");
    sessionStorage.removeItem("amount");
    sessionStorage.removeItem("times");
    router.push("/wallet");
  }

  const checkOpenDialog = () => {
    clickLoanNow();
    if (token) {
      handleClick({ isOpen: true });
    } else {
      router.push("/login");
    }
  };

  // const setFalseDialog = () => {
  //   setVisible(false);
  //   // setFalseDialogProp({ isOpen: false });
  // };

  return (
    <>
      <motion.div
        className="btn"
        whileTap={{
          opacity: 0.7,
          scale: 0.9,
        }}
        onClick={checkOpenDialog}
      >
        <Typography.Text
          className="btn-text"
          style={{ fontSize: 20, fontWeight: 700, color: "#fff" }}
        >
          Vay ngay
        </Typography.Text>
      </motion.div>
      <Modal
        className="contract-component-dialog"
        visible={visible}
        onOk={() => {
          setVisible(false);
          setFalseDialogProp({ isOpen: false });
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        closable
        onCancel={() => {
          setVisible(false);
          setFalseDialogProp({ isOpen: false });
        }}
      >
        <h5>
          <img src="logo.png" alt="" width={270} />
        </h5>
        <h5 className="company-name-title">
          <center>KEB HANA BANK GLOBAL - VIET NAM</center>
        </h5>
        <br />

        <p className="main-title">HỢP ĐỒNG CHO VAY TÍN DỤNG</p>

        <table className="ct-table">
          <tr>
            <th>THÔNG TIN NGƯỜI ĐỀ NGHỊ</th>
            <th></th>
          </tr>
          <tr>
            <td className="cl-1">Họ tên bên vay</td>
            <td className="cl-2">
              {profile?.kyc?.name || "Cập nhật khi hoàn thành"}
            </td>
          </tr>
          <tr>
            <td className="cl-1">Số Điện Thoại Đăng Ký</td>
            <td className="cl-2">
              {profile?.phone || "Cập nhật khi hoàn thành"}
            </td>
          </tr>
          <tr>
            <td className="cl-1">Số CMND/CCCD</td>
            <td className="cl-2">
              {profile?.kyc?.id_number || "Cập nhật khi hoàn thành"}
            </td>
          </tr>
          <tr>
            <td className="cl-1">Số Tiền Đề Nghị Vay</td>
            <td className="cl-2">{contract?.amount?.toLocaleString()} VNĐ</td>
          </tr>
          <tr>
            <td className="cl-1">Kỳ Hạn Vay</td>
            <td className="cl-2">{contract?.times} tháng</td>
          </tr>
          <tr>
            <td className="cl-1">Số Tiền Thanh Toán Mỗi Tháng</td>
            <td className="cl-2">
              {Math.ceil(
                (contract?.amount || 0) / (contract?.times || 1) +
                  (contract?.amount || 0) * 0.009
              ).toLocaleString()}{" "}
              VNĐ
            </td>
          </tr>
        </table>

        <br />
        <p style={{ fontWeight: "700" }}>
          BÊN A ( BÊN CHO VAY ) : KEB HANA BANK GLOBAL - VIỆT NAM ( KEB )
        </p>
        <p>
          Người đại diện: MIN PILBOO <br />
          Chức vụ: Giám Đốc Đại Diện Pháp Lý <br />
          Mã số thuế: 0100917664 <br />
          Ngày hoạt động: 1998-11-14 <br />
          Hotline : {dataCSKH.hotline} <br />
          Quản lý bởi : Cục Thuế Thành phố Hà Nội <br />
          Loại hình DN : Công ty trách nhiệm hữu hạn ngoài NN <br />
          Tình trạng: Đang hoạt động (đã được cấp GCN ĐKT) <br />
          Sau đây gọi tắt là : Bên A
        </p>

        <p className="mb-0" style={{ fontWeight: "700" }}>
          BÊN B ( BÊN VAY ) :
        </p>
        <p className="mb-0" style={{ fontWeight: "700" }}>
          Số hợp đồng : {contract?.slug}
        </p>
        <p className="mb-0" style={{ fontWeight: "700" }}>
          Ngày : {moment(contract?.created_at).format("DD/MM/YYYY")}
        </p>
        <p style={{ fontWeight: "700" }}>
          Kỳ hạn vay : {contract?.times} tháng
        </p>
        <p style={{ fontWeight: "700" }}>
          Bên Vay đề nghị và Bên Cho Vay (KEB) đồng ý cấp cho Bên Vay một Khoản
          Vay như sau:
        </p>

        <p>
          1. Loại hình vay: vay tiêu dùng thanh toán trả góp mỗi tháng gốc lẫn
          lãi <br />
          2. Phương thức cho vay: Giải ngân trực tiếp thông qua ứng dụng thanh
          toán online <br />
          3. Hình thức cho vay: cho vay trả góp không có tài sản bảo đảm <br />
          4. Phương thức giải ngân: chuyển khoản vào tài khoản của Bên Vay Cung
          cấp trên úng dụng dịch vụ thanh toán online <br />
          5. Thời hạn cho vay: tính từ ngày tiếp theo của Ngày Giải Ngân và kết
          thúc tại thời điểm Bên Vay trả hết nợ gốc và lãi tiền vay theo Lịch
          Trả Nợ đính kèm hoặc tại thời điểm Bên Vay hoàn tất toàn bộ nghĩa vụ
          thanh toán Khoản Vay, tùy thuộc điều kiện nào đến trước.
        </p>

        <p style={{ fontWeight: "700" }}>
          ĐIỀU 1: PHƯƠNG THỨC PHÁT HÀNH KHOẢN VAY
        </p>
        <p style={{ fontWeight: "700" }}>
          KEB sẽ giải ngân Khoản Vay cho Bên Vay chỉ khi tất cả các điều kiện
          sau được thỏa mãn:
        </p>
        <p>
          1.1 Hợp đồng này quy định các điều khoản chung điều chỉnh tất cả các
          dịch vụ mà Bên B cung cấp cho Bên A. <br />
          1.2. Khi cung cấp một dịch vụ cụ thể, Bên B và Bên A sẽ ký kết để nêu
          rõ nội dung dịch vụ và các điều khoản cần thiết khác. <br />
          1.3. Các điều khoản nêu trên là một phần không tách rời của hợp đồng
          này. Nếu có sự khác biệt giữa hợp đồng này và các điều khoản của hợp
          đồng này sẽ được áp dụng. <br />
          1.4. Hợp đồng này có thời hạn kể từ ngày ký và kết thúc tại thời điểm
          Ngân Hàng chấm dứt sử dụng. Tất cả dịch vụ của Bên B tại các điều
          khoản đã ký.
        </p>

        <p style={{ fontWeight: "700" }}>ĐIỀU 2 : DỊCH VỤ CUNG CẤP</p>
        <p>
          2.1. Nếu số tiền thực nhận được ít hơn số tiền vay đã thỏa thuận trong
          hợp đồng này, một trong hai bên sẽ thông báo cho bên kia qua nền tảng
          trong 3 ngày và bên A sẽ thu xếp để số dư được cộng vào thẻ ngân hàng
          của bên B trong vòng một ngày làm việc, và thời gian cho vay sẽ tự
          động bắt đầu tính <br />
          2.2. Nếu số tiền nhận lớn hơn số tiền vay đã thỏa thuận trong hợp đồng
          này, một trong hai bên sẽ thông báo cho bên kia qua nền tảng hoặc điện
          thoại trong vòng ba ngày và bên B sẽ thu xếp hoản trả số tiền vượt quá
          trong vòng một ngày làm việc. Nếu không số tiền ban đầu của khoản vay
          trong hợp đồng này sẽ được tính theo số tiền thanh toán thực tế.{" "}
          <br />
          2.3. Sau khi người dùng qua vòng xét duyệt khoản vay, bộ phận giải
          ngân sẽ thẩm định. Nếu khách hàng đủ điều kiện xét duyệt thì bộ phận
          giải ngân sẽ cung cấp mật khẩu rút tiền và hỗ trợ khách hàng giải
          ngân. <br />
          2.4. Bên A không sử dụng bất kỳ dịch vụ nào vào mục đích vi phạm pháp
          luật Việt Nam. <br />
          2.5. Các bên có nghĩa vụ tuân thủ các quy định của pháp luật Việt Nam
          về bảo vệ thông tin thu thập được từ hợp đồng này. <br />
        </p>

        <p style={{ fontWeight: "700" }}>
          ĐIỀU 3: QUYỀN HẠN VÀ TRÁCH NHIỆM CỦA CÁC BÊN
        </p>
        <p style={{ fontWeight: "700" }}>A. ĐỐI VỚI BÊN A</p>
        <p>
          3.1. - Bên B vay vốn tổ chức tín dụng phải bảo đảm sử dụng vốn vay
          đúng mục đích, hoàn trả nợ gốc và lãi 3.2. Thanh toán đầy đủ, đúng
          thời hạn các khoản cước theo quy định của hợp đồng này, kể cả trong
          thời gian chờ giải quyết khiếu nại và chịu các chi phí (như phí,
          chuyển tiền, phí ngân hàng và các chi phí khác có liên quan, nếu có)
          phát sinh khi thanh toán cước; 3.3. Bồi thường thiệt hại trực tiếp do
          lỗi của mình gây ra cho Bên B; 3.4. Cung cấp đầy đủ, chính xác thông
          tin Bên A, các tài liệu liên quan đến việc sử dụng nguồn vốn . Thông
          báo cho Bên B về sự thay đổi các thông tin đã được Bên A cung cấp bằng
          văn bản trong ngày sau khi thay đổi hoặc nhận được yêu cầu từ Bên B.
          Hợp tác với Bên B để hoàn tất các thủ tục liên quan đến việc thay đổi
          thông tin nêu trên.
        </p>
        <p style={{ fontWeight: "700" }}>B. ĐỐI VỚI BÊN B</p>
        <p>
          3.5. - Chịu hoàn toàn trách nhiệm trước Pháp luật khi không thực hiện
          đúng cam kết theo Hợp đồng đã ký theo Bộ luật dân sự 2015 (BLDS 2015)
          (Luật số 91/2015/QH13 ngày 24/11/2015) 3.6. Bảo đảm bí mật thông tin
          của Bên A, chỉ được chuyển giao thông tin cho bên thứ ba khi có sự
          đồng ý của Bên A trừ các trường hợp theo quy định Pháp luật. 3.7. Giải
          quyết khiếu nại của Bên A trong vòng 05 (năm) ngày làm việc kể từ ngày
          nhận được khiếu nại. 3.8. Không được từ chối giao kết hợp đồng hoặc
          đơn phương chấm dứt hợp đồng với Bên A trừ các trường hợp được quy
          định tại Điều 26 quỹ người vay . 3.9. Khi người vay trong quá trình
          xét duyệt khoản vay không thành công do nhiều yếu tố khác nhau như
          chứng minh thư sai, thẻ ngân hàng sai, danh bạ sai. Việc thông tin sai
          lệch này sẽ khiến hệ thống phát hiện nghi ngờ gian lận hoặc giả mạo hồ
          sơ vay và bên vay phải có trách nhiệm chủ động hợp tác với bên A đề xử
          lý.
        </p>

        <p style={{ fontWeight: "700" }}>
          ĐIỀU 4 : TRÁCH NHIỆM DO VI PHẠM HỢP ĐỒNG
        </p>
        <p>
          Tất cả các bên trong hợp đồng sẽ thực hiện nghiêm túc các nghĩa vụ
          theo hợp đồng của mình và không bên nào được chấm dứt thỏa thuận này
          trừ khi các bên đạt được thỏa thuận thông qua tham vấn hoặc theo thỏa
          thuận này. Nếu bất kỳ bên nào vi phạm hợp đồng, bên vi phạm sẽ chịu
          các chi phí và tổn thất mà các bên không phải chịu do hành vi vi phạm,
          bao gồm nhưng không giới hạn ở phí điều tra, phí kiện tụng, phí luật
          sư,.vv do bên vi phạm chịu. Nếu bên vỡ nợ là bên B thì bên A có quyền
          chấm dứt hợp đồng này ngay lập tức và yêu cầu bên B hoàn trả dư nợ
          gốc, lãi và các chi phí khác. Tại thời điểm này, bên B cung cấp thanh
          toán cho bên A tất cả các khoản phí, quản lý tài khoản, phí xét duyệt
          nhanh, phí quản lý tài khoản ( nếu có ) trong một khoảng thời gian,
          phí quản lý quá hạn ( nếu có ) và các khoản phí khác do vỡ nợ. Bên B
          thanh toán theo trình tự sau : <br />
          1) Tất cả các khoản phí khác phát sinh theo thỏa thuận này. <br />
          2) Phí quản lý tài khoản quá hạn theo quy định trên hợp đồng. <br />
          3) Lãi quá hạn theo quy định. <br />
          4) Phí quản lý tài khoản vay quy định. <br />
          5) Lãi tiền vay quy định. <br />
          6) Phí duyệt hồ sơ nhanh. <br />
          7) Phí quản lý tài khoản thông thường <br />
          8) Phí tất toán hợp đồng 5% <br />
          9) Phí đền bù hợp đồng 40% / tổng khoản vay
        </p>

        <p className="note-contract">
          Tôi xin xác nhận những thông tin trên đây cùng với các thông tin và
          tài liệu khác được tôi cung cấp cho KEB HANA BANK GLOBAL - VIỆT NAM
          trong quá trình đề nghị vay vốn là chính xác, trung thực, và tôi cũng
          đồng ý cho KEB HANA BANK GLOBAL - VIỆT NAM ( KEB )được thu thập, sử
          dụng cũng như chia sẻ cho bên thứ ba các thông tin liên quan đến tôi
          và thông tin cá nhân được ghi nhận tại bất kỳ tài liệu nào được ký bởi
          tôi (có hiệu lực tại từng thời điểm), thông tin trên CMND/Thẻ CCCD sẽ
          là thông tin cuối cùng và các tài liệu liên quan sẽ vẫn có giá trị
          pháp lý.
        </p>
        <p className="note-contract">
          Tôi đồng ý ký kết Hợp Đồng này thông qua phương tiện điện tử. Trong
          mọi trường hợp
        </p>
        <div className="sign-container">
          <div className="chu-ky">
            <p>Người vay ký</p>
            <>
              <Typography.Text>Kí vào khung bên dưới</Typography.Text>
              <div
                className="signing"
                style={{
                  border: "1px solid #555",
                  maxWidth: "400px",
                  height: "200px",
                }}
              >
                <SignatureCanvas
                  ref={padRef}
                  penColor="#666"
                  canvasProps={{
                    width:
                      window.innerWidth < 520 ? window.innerWidth - 250 : 250,
                    height: 200,
                    className: "sigCanvas",
                  }}
                />
              </div>

              <div className="refresh" style={{ marginBottom: "5xp" }}>
                <div
                  onClick={() => padRef.current.clear()}
                  style={{
                    textDecoration: "underline",
                    padding: "5px 10px 10px 10px",
                    color: "#2383ff",
                  }}
                >
                  Làm mới{" "}
                </div>
              </div>
            </>
            <p>{profile?.kyc?.name}</p>
          </div>
          <div>
            <div>Bên cho vay</div>
            <img className="con-dau" alt="" src={_signned} width="100%" />
          </div>
        </div>

        {/* <div style={{ marginTop: 20 }} className="mb-20">
          <Checkbox
            checked={ticked}
            onClick={(e) => setTicked((prev) => !prev)}
          />
          <Typography.Text style={{ padding: 5, color: "red" }}>
            Tôi xin xác nhận những thông tin trên đây cùng với các thông tin và
            tài liệu khác được tôi cung cấp cho NGÂN HÀNG TNHH MỘT THÀNH VIÊN
            STANDARD CHARTERED (VIỆT NAM) trong quá trình đề nghị vay vốn là
            chính xác, trung thực, và tôi cũng đồng ý cho NGÂN HÀNG TNHH MỘT
            THÀNH VIÊN STANDARD CHARTERED (VIỆT NAM) được thu thập, sử dụng cũng
            như chia sẻ cho bên thứ ba các thông tin liên quan đến tôi và thông
            tin cá nhân được ghi nhận tại bất kỳ tài liệu nào được ký bởi tôi
            (có hiệu lực tại từng thời điềm), thông tin trên CMND/Thẻ CCCD sẽ là
            thông tin cuối cùng và các tài liệu liên quan sẽ vẫn có giá trị pháp
            lý.
          </Typography.Text>
          <Typography.Text style={{ padding: 5, color: "red" }}>
            Tôi đồng ý ký kết Hợp Đồng này thông qua phương tiện điện tử. Trong
            mọi trường hợp.
          </Typography.Text>
        </div> */}

        <div className="button-action-group">
          <motion.div
            className="btn"
            whileTap={{
              opacity: 0.7,
              scale: 0.9,
            }}
            onClick={finishContract}
          >
            <Typography.Text
              className="btn-text"
              style={{ fontSize: 15, fontWeight: 600, color: "#fff" }}
            >
              Xác nhận
            </Typography.Text>
          </motion.div>
        </div>
      </Modal>
    </>
  );
}
