import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import _ from 'lodash';
import './Wallet.scss';
import {
  LeftOutlined,
} from '@ant-design/icons';
import {
  Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _cardbg from '../assets/cardbg.jpg';
import api from '../api';
import * as actions from '../redux/actions/auth';

export default function Wallet() {
  const { profile } = useSelector((state) => state._auth);
  const history = useHistory();
  const dispatch = useDispatch();
  // useEffect(() => {
  //   loadData();
  // }, [profile]);
  
  // const loadData = async () => {
  //   try {
  //     const { data: data2 } = await api.get('/users/profil1e');
  //     dispatch(actions.initialLogin(data2.data));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  return (
    <motion.div
      style={{ padding: '10px 15px', backgroundColor: '#eee' }}
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div style={{ padding: 10}}>
        <div>
          <Typography.Title color='#ccc' level={5}>Thông tin cá nhân</Typography.Title>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            padding: 10,
            background: '#fff',
            borderRadius: 5,
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '5px 0px',
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Họ tên :{' '}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.name}
            </Typography.Text>
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '5px 0px',
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Địa chỉ :{' '}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.address}
            </Typography.Text>
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '5px 0px',
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Số CMND/CCCD :{' '}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.id_number}
            </Typography.Text>
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '5px 0px',
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Ngày sinh :{' '}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.dob}
            </Typography.Text>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '5px 0px',
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Nghề nghiệp :{' '}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.job}
            </Typography.Text>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '5px 0px',
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Thu nhập :{' '}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.income}
            </Typography.Text>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '5px 0px',
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Mục đích khoản vay :{' '}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.purpose}
            </Typography.Text>
          </div>
        </div>

        <div>
          <Typography.Title style={{marginTop: 10}} level={5}>Thông tin người liên hệ 1</Typography.Title>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            padding: 10,
            background: '#fff',
            borderRadius: 5,
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '5px 0px',
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Họ tên :{' '}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.relative_name1}
            </Typography.Text>
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '5px 0px',
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Số điện thoại :{' '}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.relative_number1}
            </Typography.Text>
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '5px 0px',
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Quan hệ với người vay :{' '}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.relative1}
            </Typography.Text>
          </div>
        </div>

        <div>
          <Typography.Title style={{marginTop: 10}} level={5}>Thông tin người liên hệ 2</Typography.Title>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            padding: 10,
            background: '#fff',
            borderRadius: 5,
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '5px 0px',
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Họ tên :{' '}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.relative_name2}
            </Typography.Text>
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '5px 0px',
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Số điện thoại :{' '}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.relative_number2}
            </Typography.Text>
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '5px 0px',
            }}
          >
            <Typography.Text
              style={{
                paddingRight: 10,

                fontSize: 14,
                fontWeight: 500,
                flex: 2,
              }}
            >
              Quan hệ với người vay :{' '}
            </Typography.Text>
            <Typography.Text
              style={{
                flex: 2,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {profile?.kyc?.relative2}
            </Typography.Text>
          </div>
        </div>

        <motion.div
          whileTap={{ scale: 0.97, opacity: 0.3 }}
          style={{ maxWidth: 350 }}
        >
          <Item
            text="Quay lại"
            icon={
              <LeftOutlined
                style={{
                  fontSize: 26,
                  fontWeight: 'bold',
                  color: '#1040e0',
                }}
              />
            }
            onClick={
              () => history.push('/profile')
            }
          />
        </motion.div>
      </div>
    </motion.div>
  );
}

const Item = ({ text = 'title', icon, onClick = () => {} }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        padding: 10,
        borderRadius: 5,
        border: '1px solid #37add6',
        color: '#37add6 !important',
        margin: '10px 0px',
        background: '#fff',
      }}
      onClick={onClick}
    >
      {icon}
      <Typography.Text style={{ flex: 1, fontSize: 16, paddingLeft: 0, marginRight: 10 }}>
        {text}
      </Typography.Text>
      {/* <RightOutlined /> */}
    </div>
  );
};
