import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Form, Input, Typography, message, Button, Image } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import InputMask from "react-input-mask";
import Upload from "../components/Upload";
import { useHistory } from "react-router-dom";
import api from "../api/index";
import isCorrectNumberPhone from "../utils/isCorrectNumberPhone";
import converter from "../utils/converterBase64ToBinary";
import { useDispatch } from "react-redux";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { BASE_URL } from "../utils/constant";

import "./peronal.scss";

export default function ContactRelative() {
  const { profile } = useSelector((state) => state._auth);
  const dispatch = useDispatch();
  const router = useHistory();
  const [dataProfile, setDataProfile] = useState({});

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      // const { data: data2 } = await api.get("/users/profil1e");
      // setDataProfile(data2.data);
      setDataProfile(profile);
    } catch (error) {
      console.log(error);
    }
  };

  const checkHaveContactRelatives = () => {
    return (
      dataProfile.kyc &&
      dataProfile.kyc.job &&
      dataProfile.kyc.income &&
      dataProfile.kyc.purpose &&
      dataProfile.kyc.relative_name1 &&
      dataProfile.kyc.relative_number1 &&
      dataProfile.kyc.relative1 &&
      dataProfile.kyc.relative_name2 &&
      dataProfile.kyc.relative_number2 &&
      dataProfile.kyc.relative2
    );
  };

  const onFinish = async (e) => {
    try {
      let objData = {};
      if (dataProfile.kyc) {
        objData = {
          ...e,
          ...dataProfile.kyc,
        };
      } else {
        objData = {
          ...e,
        };
      }
      delete objData.status;
      await api.post("/users/verify", objData);

      router.push("/my-info");
    } catch (err) {
      message.error(err);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0.3 }}
      animate={{ opacity: 1 }}
      className="personal-info"
    >
      <div className="header-screen mb-0">
        <div className="icon-header" onClick={() => router.goBack()}>
          <LeftOutlined />
        </div>
        <span>Thông tin hồ sơ</span>
      </div>
      <div className="personal-info-body">
        {checkHaveContactRelatives() ? (
          <div>
            <p className="text-center title-main">Thông tin công việc</p>
            <div className="personal-info-body-block">
              <div className="personal-info-body-block--item">
                <p className="title">Nghề nghiệp: </p>
                <p className="value">
                  {dataProfile?.kyc?.job || "Chưa có thông tin"}
                </p>
              </div>
              <div className="personal-info-body-block--item">
                <p className="title">Thu nhập: </p>
                <p className="value">
                  {dataProfile?.kyc?.income || "Chưa có thông tin"}
                </p>
              </div>
              <div className="personal-info-body-block--item">
                <p className="title">Mục đích vay: </p>
                <p className="value">
                  {dataProfile?.kyc?.purpose || "Chưa có thông tin"}
                </p>
              </div>
            </div>
            <p className="text-center title-main">Thông tin người liên hệ 1</p>
            <div className="personal-info-body-block">
              <div className="personal-info-body-block--item">
                <p className="title">Họ tên: </p>
                <p className="value">
                  {dataProfile?.kyc?.relative_name1 || "Chưa có thông tin"}
                </p>
              </div>
              <div className="personal-info-body-block--item">
                <p className="title">Số điện thoại: </p>
                <p className="value">
                  {dataProfile?.kyc?.relative_number1 || "Chưa có thông tin"}
                </p>
              </div>
              <div className="personal-info-body-block--item">
                <p className="title">Mối quan hệ: </p>
                <p className="value">
                  {dataProfile?.kyc?.relative1 || "Chưa có thông tin"}
                </p>
              </div>
            </div>
            <p className="text-center title-main">Thông tin người liên hệ 2</p>
            <div className="personal-info-body-block">
              <div className="personal-info-body-block--item">
                <p className="title">Họ tên: </p>
                <p className="value">
                  {dataProfile?.kyc?.relative_name2 || "Chưa có thông tin"}
                </p>
              </div>
              <div className="personal-info-body-block--item">
                <p className="title">Số điện thoại: </p>
                <p className="value">
                  {dataProfile?.kyc?.relative_number2 || "Chưa có thông tin"}
                </p>
              </div>
              <div className="personal-info-body-block--item">
                <p className="title">Mối quan hệ: </p>
                <p className="value">
                  {dataProfile?.kyc?.relative2 || "Chưa có thông tin"}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="personal-info-body--form">
            <Form wrapperCol={{}} layout="vertical" onFinish={onFinish}>
              <div className="form-item--block mb-15">
                <Form.Item
                  name="job"
                  label="Nghề nghiệp"
                  rules={[
                    {
                      required: true,
                      message: "Điền nghề nghiệp của bạn",
                    },
                  ]}
                >
                  <Input placeholder="Điền nghề nghiệp" />
                </Form.Item>
                <Form.Item
                  name="income"
                  label="Thu nhập / tháng"
                  rules={[
                    {
                      required: true,
                      message: "Nhập Thu nhập của bạn",
                    },
                  ]}
                >
                  <Input placeholder="Thu nhập" />
                </Form.Item>
                <Form.Item
                  name="purpose"
                  label="Mục đích vay tiền"
                  rules={[
                    {
                      required: true,
                      message: "Mục đích vay tiền",
                    },
                  ]}
                >
                  <Input placeholder="Mục đích vay tiền" />
                </Form.Item>
                <Typography.Text strong>
                  Thông tin người liên hệ 1{" "}
                </Typography.Text>
                <Form.Item
                  name="relative_name1"
                  label="Họ tên"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập",
                    },
                  ]}
                >
                  <Input placeholder="Nhập họ tên" />
                </Form.Item>
                <Form.Item
                  name="relative_number1"
                  label="Số điện thoại"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập",
                    },
                  ]}
                >
                  <Input placeholder="Nhập số điện thoại" />
                </Form.Item>
                <Form.Item
                  name="relative1"
                  label="Quan hệ với người vay"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập",
                    },
                  ]}
                >
                  <Input placeholder="Nhập quan hệ với người vay" />
                </Form.Item>
                <Typography.Text strong>
                  Thông tin người liên hệ 2{" "}
                </Typography.Text>
                <Form.Item
                  name="relative_name2"
                  label="Họ tên"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập",
                    },
                  ]}
                >
                  <Input placeholder="Nhập họ tên" />
                </Form.Item>
                <Form.Item
                  name="relative_number2"
                  label="Số điện thoại"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập",
                    },
                  ]}
                >
                  <Input placeholder="Nhập số điện thoại" />
                </Form.Item>
                <Form.Item
                  name="relative2"
                  label="Quan hệ với người vay"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập",
                    },
                  ]}
                >
                  <Input placeholder="Nhập quan hệ với người vay" />
                </Form.Item>
              </div>
              <div className="button-group">
                <Button type="primary" shape="round" htmlType="submit">
                  Tiếp tục
                </Button>
              </div>
            </Form>
          </div>
        )}
      </div>
    </motion.div>
  );
}
