/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { motion } from "framer-motion";
import { Form, Input, Image, message, Button } from "antd";
import { PhoneOutlined, LockOutlined, LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as authActions from "../redux/actions/auth";
import * as actions from "../redux/actions/auth";
import banner from "../assets/new-ui/login.jpg";
import api from "../api";

import "./login.scss";

export default function DangKy() {
  const dispatch = useDispatch();
  const router = useHistory();
  const onFinish = async (e) => {
    try {
      const { data } = await api.post("/auth/login", e);
      // if (data.isLocked) {
      //   message.warning("Tài khoản của bạn đã bị khoá");
      //   return
      // }
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("role", data.role);
      const { data: data2 } = await api.get("/users/profile");
      dispatch(actions.initialLogin(data2.data));
      dispatch({ type: "LOGIN_SUCCESS", payload: {} });
      setTimeout(() => {
        router.push("/profile");
      }, 1000);
    } catch (err) {
      message.error(err);
    }
  };
  return (
    <motion.div initial={{ opacity: 0.3 }} animate={{ opacity: 1 }}>
      <div
        style={{
          minWidth: "90vw",
          background: "#fff",
          borderRadius: 10,
          minHeight: 120,
        }}
      >
        <div className="header-screen mb-0">
          <div className="icon-header" onClick={() => router.goBack()}>
            <LeftOutlined />
          </div>
          <span>Đăng nhập</span>
        </div>
        <div
          className="banner-background mb-10"
          style={{
            background: `url(${banner}) center center / cover no-repeat`,
          }}
        />
        <div className="login-form">
          <Form wrapperCol={{}} layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="phone"
              label="Số điện thoại đăng nhập"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập số điện thoại đăng nhập",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Số điện thoại đăng nhập"
                prefix={<PhoneOutlined />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Mật khẩu"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập mật khẩu",
                },
              ]}
            >
              <Input.Password
                size="large"
                placeholder="Mật khẩu"
                prefix={<LockOutlined />}
              />
            </Form.Item>

            <div>
              <Button
                className="button-ant-form"
                style={{ width: "100%", marginBottom: "15px" }}
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Đăng nhập
              </Button>
              <Button
                className="button-ant-form"
                style={{ width: "100%" }}
                type="primary"
                shape="round"
                onClick={() => router.push("/signup")}
              >
                Đăng ký
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </motion.div>
  );
}
