import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../redux/actions/auth";
import { Image, Button, Row, Col, Modal } from "antd";
import api from "../api";
import { useHistory } from "react-router-dom";
import _img1 from "../assets/new-ui/image1.jpg";
import _img2 from "../assets/new-ui/image2.jpg";
import _img3 from "../assets/new-ui/image3.jpg";
import _img4 from "../assets/new-ui/image4.jpg";
import _img5 from "../assets/new-ui/image5.jpg";

import "./home.scss";

export default function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const router = useHistory();
  const dispatch = useDispatch();
  const [token] = useState(localStorage.getItem("access_token"));

  // useEffect(() => {
  //   checkLockEd()
  // }, []);

  const goToLoan = () => {
    if (token) {
      (async () => {
        try {
          const { data } = await api.get("/users/profile");
          dispatch(actions.initialLogin(data.data));
          if (data.data.isLocked) {
            showModal();
          } else {
            router.push("/loan");
          }
        } catch (err) {}
      })();
    } else {
      router.push("/login");
    }
  };

  return (
    <>
      <div className="header-screen">KEB Hana Bank</div>
      <div className="body-screen">
        <div className="mb-5">
          <Image className="main-banner" src={_img1} preview={false} />
        </div>
        <Row gutter={5} className="mb-5">
          <Col span={12}>
            <Image src={_img2} preview={false} className="sub-banner" />
          </Col>
          <Col span={12}>
            <Image src={_img3} preview={false} className="sub-banner" />
          </Col>
        </Row>
        <Row gutter={5} className="mb-5">
          <Col span={12}>
            <Image src={_img4} preview={false} className="sub-banner" />
          </Col>
          <Col span={12}>
            <Image src={_img5} preview={false} className="sub-banner" />
          </Col>
        </Row>
        <ConditionLoan />
        <div className="loan-now">
          <Button
            type="primary"
            onClick={() => {
              goToLoan();
              // router.push("/loan");
            }}
          >
            Vay ngay
          </Button>
        </div>
      </div>
      <Modal className="noti-modal" visible={isModalOpen}>
        <p>Hồ sơ quý khách đang tạm khoá, vui lòng liên hệ CSKH</p>
        <div className="to-cskh" onClick={() => router.push("/cskh")}>
          CSKH
        </div>
      </Modal>
    </>
  );
}

const ConditionLoan = () => {
  return (
    <div className="condition-block">
      <p className="title">1. Điều kiện vay là gì ?</p>
      <p>
        - Có quốc tịch Việt Nam và trong độ tuổi từ 18-55 tuổi, có địa chỉ
        thường trú và địa chỉ làm việc hiện tại trong lãnh thổ Việt Nam, có
        CMND/CCCD còn hiệu lực, có thu nhập ổn định. Có các giấy tờ như bảo hiểm
        xã hội hoặc bảo hiểm y tế, hợp đồng lao động ...
      </p>

      <p className="title">2. Làm sao để đăng ký vay ?</p>
      <p>- Bạn có thể tải ứng dụng và đăng ký vay ngay trên ứng dụng</p>

      <p className="title">
        3. Khi thẩm định hồ sơ ngân hàng có tiến hành thông báo và kiểm tra
        thông tin qua người thân như vợ hoặc chồng, bạn bè của người vay không ?
      </p>
      <p className="special">- KHÔNG</p>

      <p className="note">
        * Liên hệ người thân chỉ dùng trong trường hợp vay thành công nhưng tới
        kì hạn đóng tiền người vay không thực hiện nghĩa vụ trả nợ và không thể
        liên lạc được. Lúc đó phía ngân hàng sẽ liên lạc với các liên hệ này để
        nhắc nhở và thông báo
      </p>
    </div>
  );
};
