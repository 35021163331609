import * as moment from 'moment';

const commonService = {};

commonService.maskString = (inputString) => {
  if (!inputString) return '';
  if (inputString.length < 6) {
    return inputString; // Chuỗi ngắn hơn 4 ký tự, không cần thay đổi
  }

  const prefix = inputString.slice(0, 4);
  const suffix = inputString.slice(-4);
  const maskedString = prefix + '****' + suffix;

  return maskedString;
};

commonService.generateRandomPhoneNumber = () => {
  const prefixes = ['03', '05', '07', '08', '09'];
    const randomPrefix = prefixes[Math.floor(Math.random() * prefixes.length)];
    const randomSuffix = Math.floor(10000000 + Math.random() * 90000000).toString().substring(1);
    return commonService.maskString(`${randomPrefix}${randomSuffix}`);
}

commonService.generateRandomId = (length) => {
  if (!length) length = 24;
  const characters = '00112233445566778899abcdefghijklmnopqrstuvwxyz';
  let randomId = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters[randomIndex];
  }
  return commonService.maskString(randomId);
};

commonService.generateRandomNumber = (min, max) => {
  // return (Math.random() * (max - min + 1) + min).toFixed(0);
  const steps = [10000000];
  // const randomStepIndex = Math.floor(Math.random() * steps.length);
  const selectedStep = steps[0];
  const range = (max - min) / selectedStep;
  const randomStep = Math.floor(Math.random() * range);
  const result = min + randomStep * selectedStep;
  return result.toLocaleString();
};

commonService.formatDolarMoney = (number) => {
  if (!number && number !== 0) {
    return '';
  }
  return '$ ' + number;
};

commonService.formatDolarMoneyWith2DigitsAfterDecimal = (number) => {
  if (!number && number !== 0) {
    return '';
  }
  return '$ ' + number.toFixed(2);
};

commonService.formatDolarMoneyWith2DigitsAfterDecimalWithoutDollar = (
  number
) => {
  if (!number && number !== 0) {
    return '';
  }
  return number.toFixed(2);
};

const formatDateMoment = 'DD/MM/YYYY';

commonService.formatDate = (value, rule) => {
  if (value) {
    var format = '';
    if (rule === 'date') {
      format = formatDateMoment;
    } else if (rule === 'datetime') {
      format = formatDateMoment + ' HH:mm:ss';
    } else if (rule === 'minute-second') {
      format = 'mm:ss';
    } else {
      format = rule;
    }
    return moment(new Date(value)).format(format);
  } else {
    return '';
  }
};
export default commonService;
