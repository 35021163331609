import { useState, useEffect } from "react";
import {
  HomeOutlined,
  UserOutlined,
  CreditCardOutlined,
  MoneyCollectOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import { motion } from "framer-motion";
import { useHistory, useLocation } from "react-router-dom";
import { Typography } from "antd";
import { useSelector } from "react-redux";

export default function Navigator() {
  const history = useHistory();
  const localtion = useLocation();
  const [current, setCurrent] = useState("home");
  const { status } = useSelector((state) => state._auth);

  useEffect(() => {
    setCurrent(localtion.pathname);
  }, [localtion.pathname]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 55,
        padding: "0px 15px 0px",
        borderTop: "1px solid #eee",
        background: "#fff",
      }}
    >
      {/* Trang chủ */}
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 45,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          background: "#fff",
        }}
        onClick={() => {
          history.push("/home");
          setCurrent("/home");
        }}
      >
        <HomeOutlined
          style={{
            fontSize: 20,
            color: current === "/home" ? "var(--active-menu-color)" : "#666",
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: current === "/home" ? "var(--active-menu-color)" : "#666",
            fontWeight: current === "/home" ? "700" : "400",
          }}
        >
          Trang chủ
        </Typography.Text>
      </motion.div>

      {/* Số tiền vay */}
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 60,
          borderRadius: 25,
          minWidth: 60,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        onClick={() => {
          setCurrent("/loan");
          history.push("/loan");
        }}
      >
        <MoneyCollectOutlined
          style={{
            fontSize: 20,
            color: current === "/loan" ? "var(--active-menu-color)" : "#666",
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: current === "/loan" ? "var(--active-menu-color)" : "#666",
            fontWeight: current === "/loan" ? "700" : "400",
          }}
        >
          Số tiền vay
        </Typography.Text>
      </motion.div>

      {/* Ví tền */}
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 60,
          borderRadius: 25,
          minWidth: 60,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        onClick={() => {
          setCurrent("/wallet");
          history.push("/wallet");
        }}
      >
        <CreditCardOutlined
          style={{
            fontSize: 20,
            color: current === "/wallet" ? "var(--active-menu-color)" : "#666",
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: current === "/wallet" ? "var(--active-menu-color)" : "#666",
            fontWeight: current === "/wallet" ? "700" : "400",
          }}
        >
          Ví tiền
        </Typography.Text>
      </motion.div>

      {/* CSKH */}
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 60,
          borderRadius: 25,
          minWidth: 60,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        onClick={() => {
          setCurrent("/cskh");
          history.push("/cskh");
        }}
      >
        <CustomerServiceOutlined
          style={{
            fontSize: 20,
            color: current === "/cskh" ? "var(--active-menu-color)" : "#666",
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: current === "/cskh" ? "var(--active-menu-color)" : "#666",
            fontWeight: current === "cskh" ? "700" : "400",
          }}
        >
          CSKH
        </Typography.Text>
      </motion.div>

      {/* Hồ sơ */}
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 45,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          background: "#fff",
        }}
        onClick={() => {
          history.push("/profile");
          history.push(status ? "profile" : "login");
          setCurrent("/profile");
        }}
      >
        <UserOutlined
          style={{
            fontSize: 20,
            color: current === "/profile" ? "var(--active-menu-color)" : "#666",
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: current === "/profile" ? "var(--active-menu-color)" : "#666",
            fontWeight: current === "/profile" ? "800" : "400",
          }}
        >
          Hồ sơ
        </Typography.Text>
      </motion.div>
    </div>
  );
}
