/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Avatar, Modal } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api from "../api";
import * as actions from "../redux/actions/auth";
import icon1 from "../assets/new-ui/icon/worker-id-card.png";
import icon2 from "../assets/new-ui/icon/permit-card.png";
import icon3 from "../assets/new-ui/icon/bank-card.png";
import iconDone from "../assets/new-ui/icon/done.png";

import "./my-info.scss";

export default function MyInfo() {
  const { profile } = useSelector((state) => state._auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const localtion = useLocation();
  const [dataProfile, setDataProfile] = useState({});
  const router = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    loadData();
  }, [localtion.pathname]);

  const loadData = async () => {
    // setDataProfile(profile)
    try {
      const { data: data2 } = await api.get("/users/profile");
      dispatch(actions.initialLogin(data2.data));
      setDataProfile(data2.data);
    } catch (error) {
      console.log(error);
    }
  };

  const checkHaveUserInfo = () => {
    return (
      dataProfile.kyc &&
      dataProfile.kyc.name &&
      dataProfile.kyc.address &&
      dataProfile.kyc.dob &&
      dataProfile.kyc.id_number &&
      dataProfile.kyc.id_back &&
      dataProfile.kyc.id_front &&
      dataProfile.kyc.id_with_face &&
      dataProfile.kyc.marriage &&
      dataProfile.kyc.education
    );
  };

  const checkHaveContactRelatives = () => {
    return (
      dataProfile.kyc &&
      dataProfile.kyc.job &&
      dataProfile.kyc.income &&
      dataProfile.kyc.purpose &&
      dataProfile.kyc.relative_name1 &&
      dataProfile.kyc.relative_number1 &&
      dataProfile.kyc.relative1 &&
      dataProfile.kyc.relative_name2 &&
      dataProfile.kyc.relative_number2 &&
      dataProfile.kyc.relative2
    );
  };

  const checkHaveBank = () => {
    return dataProfile.kyc && dataProfile.kyc.bank;
  };

  const goToAnotherPage = async (path) => {
    const { data } = await api.get("/users/profile");
    if (data.data.isLocked) {
      showModal();
      return
    } 
    router.push(path);
  }

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="my-info"
    >
      <div className="header-screen mb-0">
        <div className="icon-header" onClick={() => router.push("/profile")}>
          <LeftOutlined />
        </div>
        <span>Thông tin của tôi</span>
      </div>
      <div className="body-my-info">
        <div className="body-my-info--block mb-10">
          {/* Thông tin cá nhân */}
          <div
            className="body-my-info--block__item"
            onClick={async () => {
              goToAnotherPage("/personal-information");
              // router.push("/personal-information");
            }}
          >
            <div className="prefix-icon">
              <Avatar src={icon1} size={50} />
            </div>
            <div className="text-block">
              <p className="title">Thông tin cá nhân</p>
              <p className="sub-title">Dữ liệu về bản thân</p>
            </div>
            <div className="surfix-icon">
              <RightOutlined />
            </div>
            {checkHaveUserInfo() ? (
              <div className="done-icon">
                <Avatar src={iconDone} size={30} />
              </div>
            ) : (
              <div className="done-icon"></div>
            )}
          </div>
          {/* Thông tin công việc và người liên hệ */}
          <div
            className="body-my-info--block__item"
            onClick={async () => {
              goToAnotherPage("/contact-realtive");
              // router.push("/contact-realtive");
            }}
          >
            <div className="prefix-icon">
              <Avatar src={icon2} size={50} />
            </div>
            <div className="text-block">
              <p className="title">Thông tin công việc và người liên hệ</p>
              <p className="sub-title">
                Dữ liệu công việc và các mối liên hệ người thân
              </p>
            </div>
            <div className="surfix-icon">
              <RightOutlined />
            </div>
            {checkHaveContactRelatives() ? (
              <div className="done-icon">
                <Avatar src={iconDone} size={30} />
              </div>
            ) : (
              <div className="done-icon"></div>
            )}
          </div>
          {/* Tài khoản cá nhân */}
          <div
            className="body-my-info--block__item mb-20"
            onClick={async () => {
              goToAnotherPage("/bank-information");
              // router.push("/bank-information");
            }}
          >
            <div className="prefix-icon">
              <Avatar src={icon3} size={50} />
            </div>
            <div className="text-block">
              <p className="title">Tài khoản cá nhân</p>
              <p className="sub-title">
                Tài khoản ngân hàng, vui lòng nhập chính xác
              </p>
            </div>
            <div className="surfix-icon">
              <RightOutlined />
            </div>
            {checkHaveBank() ? (
              <div className="done-icon">
                <Avatar src={iconDone} size={30} />
              </div>
            ) : (
              <div className="done-icon"></div>
            )}
          </div>
        </div>

        <div className="note mb-20">
          Tôi cam đoan những thông tin trên là chính xác và chịu hoàn toàn mọi
          trách nhiệm nếu có bất cứ sai sót nào về nội dung thông tin đã điền
        </div>
        <div className="button-action">
          <div
            className="fake-button"
            onClick={async () => {
              goToAnotherPage("/loan");
              // router.push("/loan");
            }}
          >
            Vay ngay
          </div>
        </div>
      </div>
      <Modal className="noti-modal" visible={isModalOpen}>
        <p>Hồ sơ quý khách đang tạm khoá, vui lòng liên hệ CSKH</p>
        <div className="to-cskh" onClick={() => router.push("/cskh")}>
          CSKH
        </div>
      </Modal>
    </motion.div>
  );
}
