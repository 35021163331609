/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Form, Input, Typography, message, Button } from 'antd';
import { PhoneOutlined, LockOutlined, LeftOutlined } from "@ant-design/icons";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as authActions from '../redux/actions/auth';
import isCorrectNumberPhone from '../utils/isCorrectNumberPhone';
import api from '../api';

import './loan.scss'

export default function DangKy() {
  const dispatch = useDispatch();

  const router = useHistory();
  const onFinish = async (e) => {
    try {
      if (!isCorrectNumberPhone(e.phone)) {
        message.error('Số điện thoại không đúng định dạng.');
        return;
      }

      if (!e.password || !e.password2) {
        message.error('Vui lòng nhập mật khẩu!');
        return;
      }

      if (e.password !== e.password2) {
        message.error('Nhập lại mật khẩu không trùng khớp!');
        return;
      }

      const { data } = await api.post('/auth/signup', {
        phone: e.phone,
        password: e.password,
      });
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('role', data.role);
      dispatch({ type: 'LOGIN_SUCCESS', payload: {} });
      message.success('Tạo tài khoản thành công.');

      router.push('/home');
    } catch (err) {
      message.error(err);
    }
  };
  return (
    <motion.div initial={{ opacity: 0.3 }} animate={{ opacity: 1 }}>
      <div className="header-screen mb-0">
        <div className="icon-header" onClick={() => router.goBack()}>
          <LeftOutlined />
        </div>
        <span>Đăng ký</span>
      </div>
      <div
        style={{
          minWidth: "90vw",
          background: "#fff",
          borderRadius: 10,
          minHeight: 120,
          padding: 10,
        }}
      >
        <div className="login-form">
          <Form wrapperCol={{}} layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="phone"
              label="Số điện thoại đăng nhập"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập số điện thoại đăng nhập",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Số điện thoại đăng nhập"
                prefix={<PhoneOutlined />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Mật khẩu"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập mật khẩu",
                },
              ]}
            >
              <Input.Password
                size="large"
                placeholder="Mật khẩu"
                prefix={<LockOutlined />}
              />
            </Form.Item>

            <Form.Item
              name="password2"
              label="Nhập lại mật khẩu"
              rules={[
                {
                  required: true,
                  message: "vui lòng nhập lại mật khẩu",
                },
              ]}
            >
              <Input.Password
                size="large"
                placeholder="Nhập lại mật khẩu"
                prefix={<LockOutlined />}
              />
            </Form.Item>

            <div className="link-signup">
              <span onClick={() => router.push("/login")}>
                Bạn đã có tài khoản?
              </span>
            </div>

            <div>
              <Button
                className="button-ant-form"
                style={{ width: "100%", marginBottom: "10px" }}
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Đăng ký tài khoản
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </motion.div>
  );
}
