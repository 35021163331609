import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Form, Input, Typography, message, Button, Image, Select } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import InputMask from "react-input-mask";
import Upload from "../components/Upload";
import { useHistory } from "react-router-dom";
import api from "../api/index";
// import converter from "../utils/converterBase64ToBinary";
// import axios from "axios";
// import SignatureCanvas from "react-signature-canvas";
// import { BASE_URL } from "../utils/constant";

import "./peronal.scss";

export default function DangKy() {
  const { profile } = useSelector((state) => state._auth);
  const dispatch = useDispatch();
  const padRef = useRef();
  const router = useHistory();
  const [idFront, setIdFront] = useState("");
  const [idBack, setIdBack] = useState("");
  const [face, setFace] = useState("");
  const [dataProfile, setDataProfile] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      // const { data: data2 } = await api.get("/users/profil1e");
      // console.log("data2", data2);
      // setDataProfile(data2.data);
      // console.log("dataProfile", dataProfile);
      setDataProfile(profile);
      form.setFieldsValue({
        name: profile.kyc.name,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const checkHaveInfo = () => {
    return (
      dataProfile.kyc &&
      dataProfile.kyc.name &&
      dataProfile.kyc.address &&
      dataProfile.kyc.dob &&
      dataProfile.kyc.id_number &&
      dataProfile.kyc.id_back &&
      dataProfile.kyc.id_front &&
      dataProfile.kyc.id_with_face &&
      dataProfile.kyc.marriage &&
      dataProfile.kyc.education
    );
  };

  const checkFullInfo = (data) => {
    return (
      data.name &&
      data.address &&
      data.dob &&
      data.id_number &&
      data.marriage &&
      data.education &&
      data.free_time
    );
  };

  const onFinish = async (e) => {
    // if (padRef.current.isEmpty()) {
    //   message.error("Vui lòng ký tên để hoàn thành đăng ký!");
    //   return;
    // }
    try {
      if (!checkFullInfo(e)) {
        message.error("Vui lòng nhập đầy đủ thông tin!");
        return;
      }
      if (idFront === "" || idBack === "" || face === "") {
        message.error(
          "Vui lòng tải lên đầy đủ ảnh mặt trước, mặt sau CMT/CCCD và ảnh chân dung!"
        );
        return;
      }
      const { data } = await api.get(
        `/users/check-have-id-number?idNumber=${e.id_number}`
      );
      if (data?.data) {
        message.error("Số CMND/CCCD đã được sử dụng!");
        return;
      }
      let objData = {};
      // let formData = new FormData();
      // const dataURI = converter(padRef.current.toDataURL());
      // formData.append("file", dataURI);

      // const { data: signUrl } = await axios.post(
      //   BASE_URL + "/upload",
      //   formData,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.getItem("access_token")}`,Í
      //     },
      //   }
      // );
      if (dataProfile.kyc) {
        objData = {
          ...e,
          id_front: idFront,
          id_back: idBack,
          id_with_face: face,
          ...dataProfile.kyc,
        };
        // chu_ky: signUrl.secure_url,
      } else {
        objData = {
          ...e,
          id_front: idFront,
          id_back: idBack,
          id_with_face: face,
        };
      }
      delete objData.status;

      await api.post("/users/verify", objData);

      router.push("/my-info");
    } catch (err) {
      message.error(err);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0.3 }}
      animate={{ opacity: 1 }}
      className="personal-info"
    >
      <div className="header-screen mb-0">
        <div className="icon-header" onClick={() => router.goBack()}>
          <LeftOutlined />
        </div>
        <span>Thông tin cơ bản</span>
      </div>
      <div className="personal-info-body">
        {checkHaveInfo() ? (
          <div className="personal-info-body-block">
            <div className="personal-info-body-block--item">
              <p className="title">Họ tên: </p>
              <p className="value">
                {dataProfile?.kyc?.name || "Chưa có thông tin"}
              </p>
            </div>
            <div className="personal-info-body-block--item">
              <p className="title">Ngày sinh: </p>
              <p className="value">
                {dataProfile?.kyc?.dob || "Chưa có thông tin"}
              </p>
            </div>
            <div className="personal-info-body-block--item">
              <p className="title">Địa chỉ: </p>
              <p className="value">
                {dataProfile?.kyc?.address || "Chưa có thông tin"}
              </p>
            </div>
            <div className="personal-info-body-block--item">
              <p className="title">Trình độ học vấn: </p>
              <p className="value">
                {dataProfile?.kyc?.education || "Chưa có thông tin"}
              </p>
            </div>
            <div className="personal-info-body-block--item">
              <p className="title">Tình trạng hôn nhân: </p>
              <p className="value">
                {dataProfile?.kyc?.marriage || "Chưa có thông tin"}
              </p>
            </div>
            <div className="personal-info-body-block--item">
              <p className="title">Thời gian rảnh: </p>
              <p className="value">
                {dataProfile?.kyc?.free_time || "Chưa có thông tin"}
              </p>
            </div>
            <div className="personal-info-body-block--item">
              <p className="title">CMND/CCCD: </p>
              <p className="value">
                {dataProfile?.kyc?.id_number || "Chưa có thông tin"}
              </p>
            </div>
            <div className="personal-info-body-block--item">
              <p className="title">Mặt trước CMND: </p>
              <div className="value">
                <Image
                  width={300}
                  src={dataProfile?.kyc?.id_front}
                  preview={false}
                />
              </div>
            </div>
            <div className="personal-info-body-block--item">
              <p className="title">Mặt sau CMND: </p>
              <div className="value">
                <Image
                  width={300}
                  src={dataProfile?.kyc?.id_back}
                  preview={false}
                />
              </div>
            </div>
            <div className="personal-info-body-block--item">
              <p className="title">Ảnh chân dung: </p>
              <div className="value">
                <Image
                  width={200}
                  src={dataProfile?.kyc?.id_with_face}
                  preview={false}
                />
              </div>
            </div>
            {/* <div className="personal-info-body-block--item">
              <p className="title">Chữ ký: </p>
              <div className="value sign">
                <Image
                  width={200}
                  src={dataProfile?.kyc?.chu_ky}
                  preview={false}
                />
              </div>
            </div> */}
          </div>
        ) : (
          <div className="personal-info-body--form">
            <Form
              wrapperCol={{}}
              layout="vertical"
              form={form}
              onFinish={onFinish}
            >
              <div className="form-item--block mb-15">
                <Form.Item
                  name="name"
                  label="Họ tên"
                  rules={[
                    {
                      required: true,
                      message: "Nhập tên của bạn",
                    },
                  ]}
                >
                  <Input placeholder="Nhập họ tên" />
                </Form.Item>
                <Form.Item
                  label="Ngày sinh"
                  rules={[
                    {
                      required: true,
                      message: "Nhập ngày sinh của bạn",
                    },
                  ]}
                  name="dob"
                >
                  <InputMask
                    mask="99/99/9999"
                    maskPlaceholder="dd/mm/yyyy"
                    placeholder="Ngày / Tháng / Năm"
                    style={{
                      borderRadius: 5,
                      padding: "5px 7px",
                      fontSize: 14,
                      width: "100%",
                      border: "1px solid #eaeaea",
                    }}
                    className="mask-focus"
                  />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="Địa chỉ"
                  rules={[
                    {
                      required: true,
                      message: "Nhập Địa chỉ của bạn",
                    },
                  ]}
                >
                  <Input placeholder="Địa chỉ" />
                </Form.Item>
                <Form.Item
                  name="education"
                  label="Trình độ học vấn"
                  rules={[
                    {
                      required: true,
                      message: "Nhập trình độ học vấn của bạn",
                    },
                  ]}
                >
                  <Input placeholder="Trình độ học vấn" />
                </Form.Item>
                <Form.Item
                  name="marriage"
                  label="Tình trạng hôn nhân"
                  rules={[
                    {
                      required: true,
                      message: "Nhập tình trạng hôn nhân của bạn",
                    },
                  ]}
                >
                  <Input placeholder="Tình trạng hôn nhân" />
                </Form.Item>
                <Form.Item
                  name="free_time"
                  label="Thời gian rảnh"
                  rules={[
                    {
                      required: true,
                      message: "Chọn thời gian rảnh",
                    },
                  ]}
                >
                  <Select placeholder="Thời gian rảnh">
                    <Select.Option value="Sáng">Sáng</Select.Option>
                    <Select.Option value="Chiều">Chiều</Select.Option>
                    <Select.Option value="Cả ngày">Cả ngày</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="id_number"
                  label="Số CMND/CCCD"
                  rules={[
                    {
                      required: true,
                      message: "Nhập CMND/CCCD của bạn",
                    },
                  ]}
                >
                  <Input placeholder="CMND/CCCD" />
                </Form.Item>
                <Form.Item label="Hình ảnh CMND/CCCD">
                  <Upload type={"front"} onDispatch={(e) => setIdFront(e)} />
                  <Upload type={"back"} onDispatch={(e) => setIdBack(e)} />
                  <Upload
                    type={"id_with_face"}
                    onDispatch={(e) => setFace(e)}
                  />
                </Form.Item>
                {/* <>
                  <Typography.Text>Kí vào khung bên dưới </Typography.Text>
                  <div
                    className="signing"
                    style={{
                      border: "1px solid #555",
                      maxWidth: "400px",
                      height: "200px",
                    }}
                  >
                    <SignatureCanvas
                      ref={padRef}
                      penColor="#666"
                      canvasProps={{
                        width:
                          window.innerWidth - 60 < 400
                            ? window.innerWidth - 60
                            : 400,
                        height: 200,
                        className: "sigCanvas",
                      }}
                    />
                  </div>

                  <div className="refresh" style={{ marginBottom: "5xp" }}>
                    <div
                      onClick={() => padRef.current.clear()}
                      style={{
                        textDecoration: "underline",
                        padding: "5px 10px 10px 10px",
                        color: "#2383ff",
                      }}
                    >
                      Làm mới{" "}
                    </div>
                  </div>
                </> */}
              </div>
              <div className="button-group mb-10">
                <Button type="primary" shape="round" htmlType="submit">
                  Tiếp tục
                </Button>
              </div>
            </Form>
          </div>
        )}
      </div>
    </motion.div>
  );
}
